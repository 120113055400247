import { render, staticRenderFns } from "./half_2018.vue?vue&type=template&id=4825adee&scoped=true&"
import script from "./half_2018.vue?vue&type=script&lang=js&"
export * from "./half_2018.vue?vue&type=script&lang=js&"
import style0 from "./half_2018.vue?vue&type=style&index=0&id=4825adee&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.8_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4825adee",
  null
  
)

export default component.exports