<template>
  <div class="half2018-component">
    <div class="img-box img-box1">
      <img src="./img/section1-img.jpg" alt="" class="single-img" />
    </div>
    <div class="img-box img-box2">
      <img src="./img/section2-img.jpg" alt="" class="single-img" />
    </div>
    <div class="img-box img-box3">
      <img src="./img/section3-img.jpg" alt="" class="single-img" />
    </div>
    <div class="img-box img-box4">
      <img src="./img/section4-img.jpg" alt="" class="single-img" />
    </div>
    <div class="img-box img-box5">
      <img src="./img/section5-img.jpg" alt="" class="single-img" />
    </div>
    <div class="img-box img-box6">
      <img src="./img/section6-img.jpg" alt="" class="single-img" />
    </div>
    <div class="img-box img-box7">
      <img src="./img/section7-img.jpg" alt="" class="single-img" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Half2018',
  data() {
    return {
      //
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.half2018-component {
  width: 100%;
}
.half2018-component .img-box {
  margin-top: -1px;
}
.half2018-component .single-img {
  width: 100%;
  height: auto;
}
</style>
